import cn from 'classnames';
import { Box } from 'uikitv2/Box';
import { SquareButton } from 'uikitv2/Buttons';
import { Icon } from 'uikitv2/Icon';
import { fieldsMap } from '../helpers';
import { labelLine } from './helpers';
import { RightProps } from './types';
import classes from './Right.module.scss';

export const Right = ({
  data, price, onDelete, isAdded,
}: RightProps) => {
  return (
    <Box direction="column" className={classes.content} justifyContent="space-between">
      {data && (
        <Box className={cn(classes.paper, classes.params)}>
          {Object.entries(data).map(([k, v]) => (
            <Box key={k} direction="column" className={classes.group}>
              <span className={classes.label}>{fieldsMap[k]}</span>
              <span className={classes['price-text']}>{v}</span>
            </Box>
          ))}
        </Box>
      )}
      {!!price?.length && isAdded && (
        <Box className={classes['added-box']} direction="column" justifyContent="space-between">
          <Box justifyContent="space-between" alignItems="center">
            <h3 className={classes['label-line']}>{labelLine}</h3>
            <SquareButton
              onClick={onDelete}
              size="small"
              variant="transparent"
              data-testid="button-trash"
            >
              <Icon
                name="trash"
                className={classes.icon}
              />
            </SquareButton>
          </Box>
          {price.map(({ type, sum }) => (
            <Box key={type} direction="column">
              <span className={classes.label}>{type}</span>
              <span className={classes.priceText}>{sum}</span>
            </Box>
          ))}
        </Box>
      )}
    </Box>
  );
};
