import { File } from 'lib/features/spProvider/types';
import { useAuth } from 'hooks/auth/useAuth';
import { useGetFilesQuery } from 'lib/features/secretKeeper/api';

export interface UseSecretKeeperFiles {
  files?: File[];
  loading: boolean;
  refetch: () => void;
}

export const useSecretKeeperFiles = (skip?: boolean): UseSecretKeeperFiles => {
  const { isUserConnected, isProviderSecretKeeper } = useAuth();
  const response = useGetFilesQuery(
    null,
    { skip: !isUserConnected || !isProviderSecretKeeper || skip, refetchOnMountOrArgChange: true },
  );

  return {
    files: response.data,
    loading: response.isLoading,
    refetch: response.refetch,
  };
};