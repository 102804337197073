import { memo, useMemo } from 'react';
import { OfferType } from '@super-protocol/sdk-js';

import { Box } from 'uikitv2/Box';
import { Billet } from './Billet';
import { PricingProps } from './types';
import {
  text, getTypeOf, getFormattedData, getFormattedOptions, descr, title,
} from './helpers';
import classes from './Pricing.module.scss';

export const Pricing = memo(({
  type,
  slots: slotsData,
  options: optionsData,
  selectedSlots,
  onAddSlot,
  onDeleteSlot,
  isCanUpdateSlot,
  isShowCounter,
  loading,
}: PricingProps) => {
  const params = useMemo(() => ({
    text: type ? text[type] : '',
    title: type ? type === OfferType.TeeOffer ? 'Slots' : `${getTypeOf(type)} Requirements` : '',
  }), [type]);
  const data = useMemo(() => getFormattedData(slotsData), [slotsData]);
  const [slotsDescr, optionsDescr] = descr;
  const { slot, options } = useMemo(() => selectedSlots || {}, [selectedSlots]);
  const dataOptions = useMemo(() => getFormattedOptions(optionsData), [optionsData]);

  return (
    <Box direction="column" className={classes.content}>
      {!!params.text && <div>{params.text}</div>}
      {!!params.title && <div className={classes.title}>{title}</div>}
      {type === OfferType.TeeOffer && <div className={classes.descr}>{slotsDescr}</div>}
      <Box className={classes.billets} direction="column">
        {data.map((item) => {
          const isSelected = slot?.id === item?.id?.value;
          return (
            <Billet
              data={item}
              isSelected={isSelected}
              key={item.id.value}
              value={isSelected ? slot?.count : 0}
              onAdd={onAddSlot}
              onDelete={onDeleteSlot}
              isCanUpdate={isCanUpdateSlot}
              isShowCounter={isShowCounter}
              loading={loading}
            />
          );
        })}
      </Box>
      {optionsData && (
        <>
          <div className={classes.title}>Options</div>
          {type === OfferType.TeeOffer && <div className={classes.descr}>{optionsDescr}</div>}
          <Box className={classes.billets} direction="column">
            {dataOptions.map((item) => {
              const id = item?.id?.value;
              const optionCount = options?.find(({ id: optionId }) => id === optionId)?.count || 0;
              return (
                <Billet
                  data={item}
                  key={item.id.value}
                  value={optionCount}
                  isShowCounter={isShowCounter}
                  isCanUpdate={false}
                  loading={loading}
                />
              );
            })}
          </Box>
        </>
      )}
    </Box>
  );
});
