import {
  memo, FC, useCallback, MouseEvent, useMemo,
} from 'react';

import { SquareButton } from 'uikitv2/Buttons/SquareButton';
import { Icon } from 'uikitv2/Icon';
import { useAppSelector } from 'lib/hooks';
import { themeSelector } from 'lib/features/theme';
import { AdderBtnProps } from './types';
import classes from './AdderBtn.module.scss';
import { deleteBtnPropsBySize, addBtnPropsBySize } from './helpers';

export const AdderBtn: FC<AdderBtnProps> = memo(({
  isAdded, onAdd, onDelete, disabled, className, dataTestId, size = 'small', variantDeleteBtn = 'black',
}) => {
  const theme = useAppSelector(themeSelector);
  const onClickDelete = useCallback((e: MouseEvent) => {
    if (!onDelete) return null;
    e.stopPropagation();
    onDelete?.();
  }, [onDelete]);
  const onClickAdd = useCallback((e: MouseEvent) => {
    if (!onAdd) return null;
    e.stopPropagation();
    onAdd?.();
  }, [onAdd]);
  const getDeleteBtnPropsBySize = useMemo(() => deleteBtnPropsBySize[size] || deleteBtnPropsBySize.medium, [size]);
  const getDAddBtnPropsBySize = useMemo(() => addBtnPropsBySize[size] || addBtnPropsBySize.medium, [size]);
  if (isAdded) {
    if (!onClickDelete) return null;
    return (
      <SquareButton
        theme={theme}
        className={className}
        onClick={onClickDelete}
        disabled={disabled}
        size={size}
        variant={variantDeleteBtn}
        data-testid={dataTestId ? `button-${dataTestId}-trash` : ''}
      >
        <Icon
          name={getDeleteBtnPropsBySize.icon}
          className={classes.icon}
          width={getDeleteBtnPropsBySize.width}
          height={getDeleteBtnPropsBySize.height}
        />
      </SquareButton>
    );
  }
  if (!onClickAdd) return null;

  return (
    <SquareButton
      theme={theme}
      className={className}
      onClick={onClickAdd}
      disabled={disabled}
      size={size}
      data-testid={dataTestId ? `button-${dataTestId}-plus` : ''}
    >
      <Icon name={getDAddBtnPropsBySize.icon} width={getDAddBtnPropsBySize.width} height={getDAddBtnPropsBySize.height} />
    </SquareButton>
  );
});

export default AdderBtn;