import { useMemo } from 'react';
import { Offer, TeeOffer } from 'generated/types';
import { FormContent } from 'lib/features/createOrderV2/types';
import { getOffersAndTeeOffersIds } from 'lib/features/createOrderV2/helpers';
import { useGetOffersQuery } from 'lib/features/offers';
import { useGetTeeOffersQuery } from 'lib/features/teeOffers';

export const useOffersAndTeeOffersFromFormContent = (formContent: FormContent) => {
  const {
    offers: offersIds,
    teeOffers: teeOffersIds,
  } = useMemo(() => getOffersAndTeeOffersIds(formContent), [formContent]);
  const offersRespones = useGetOffersQuery(
    { filter: { ids: offersIds }, pagination: { first: null } },
    { skip: !offersIds?.length },
  );
  const teeOffersRespones = useGetTeeOffersQuery(
    { filter: { ids: teeOffersIds }, pagination: { first: null } },
    { skip: !teeOffersIds?.length },
  );
  const offers = useMemo(
    () => (offersRespones?.data?.result?.page?.edges || []).map(({ node }) => node as Offer),
    [offersRespones],
  );
  const teeOffers = useMemo(
    () => (teeOffersRespones?.data?.result?.page?.edges || []).map(({ node }) => node as TeeOffer),
    [teeOffersRespones],
  );

  return {
    loading: offersRespones?.isLoading || teeOffersRespones?.isLoading,
    offers,
    teeOffers,
  };
};