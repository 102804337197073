/* eslint-disable max-len */
import { OfferType } from '@super-protocol/sdk-js';
import { BigNumber } from 'bignumber.js';
import {
  OfferSlot, TeeOfferOption, TeeOfferSlot, SlotUsage,
} from 'generated/types';
import { getFixedDeposit } from 'utils/sdk/utils';
import {
  getOfferSlotValueFormatted, getOfferSlotLabelFormatted, OfferSlotsInfo, getOfferSlotExtension, priceTypeMap, offerSlotsInfoList,
} from 'utils/slots';
import { Value, FormattedBilletData } from './types';

export const text = {
  [OfferType.Data]: 'The provider is offering the following pricing plans for scaling of the data usage. Please select the plan most compatible with your intended compute configuration.',
  [OfferType.TeeOffer]: 'This compute provider is offering the following slots and options. Please select configurations most appropriate for your solution and data requirements and intended usage scenario.',
  [OfferType.Solution]: 'The provider is offering the following pricing plans for scaling of the solution. Please select the requirements most compatible with your intended compute configuration.',
  [OfferType.Storage]: '',
};

export const descr = [
  'You can select one computing slot and apply increments to it.',
  'You can combine multiple options and apply increments to each. ',
];

export const title = 'Solution Requirements';

export const getTypeOf = (type: OfferType) => {
  return Object.entries(OfferType).reduce((acc, [k, v]) => ({ ...acc, [v]: k }), {})[type];
};

export const getObjectEntries = (obj: object, accum = []): Value[] => {
  if (!obj) return [];
  return Object.entries(obj).reduce((acc: Value[], [k, v]) => ([...acc, {
    label: getOfferSlotLabelFormatted(k as keyof OfferSlotsInfo), // todo
    value: `${getOfferSlotValueFormatted(k as keyof OfferSlotsInfo, v)} ${getOfferSlotExtension(k as keyof OfferSlotsInfo, v)}`, // todo
  }]), accum);
};

const getTimeValue = (usage?: SlotUsage): Value[] => {
  return [
    {
      label: getOfferSlotLabelFormatted('minTimeMinutes'),
      value: `${getOfferSlotValueFormatted('minTimeMinutes', usage?.minTimeMinutes)} ${getOfferSlotExtension('minTimeMinutes', usage?.minTimeMinutes)}`,
    },
    {
      label: getOfferSlotLabelFormatted('maxTimeMinutes'),
      value: `${getOfferSlotValueFormatted('maxTimeMinutes', usage?.maxTimeMinutes)} ${getOfferSlotExtension('maxTimeMinutes', usage?.maxTimeMinutes)}`,
    },
  ];
};

const sortInfoList = (obj: Record<string, number | string>): Record<string, number | string> => {
  const sortedKeys = Object.keys(obj).sort((a, b) => (
    (offerSlotsInfoList.indexOf(a as keyof OfferSlotsInfo) || 0) - (offerSlotsInfoList.indexOf(b as keyof OfferSlotsInfo) || 0)
  ));
  return sortedKeys.reduce((acc, item) => ({
    ...acc,
    [item]: obj[item],
  }), {});
};

export const getFormattedData = (slots?: OfferSlot[] | TeeOfferSlot[]): FormattedBilletData[] => {
  if (!slots) return [];
  return [...slots]
    .sort((slotA: OfferSlot | TeeOfferSlot, slotB: OfferSlot | TeeOfferSlot) => {
      const { priceType: priceTypeA, price: priceA } = slotA.usage || {};
      const { priceType: priceTypeB, price: priceB } = slotB.usage || {};
      if (priceTypeA < priceTypeB) return -1;
      if (priceTypeA > priceTypeB) return 1;
      const priceABn = new BigNumber(priceA);
      const priceBBn = new BigNumber(priceB);
      if (priceABn.isGreaterThan(priceBBn)) return 1;
      if (priceBBn.isGreaterThan(priceABn)) return -1;
      return 0;
    })
    .map((item) => {
      const {
        id, usage, option, info,
      } = item as OfferSlot;
      sortInfoList(info);
      const timeValue = getTimeValue(usage);
      return ({
        id: {
          label: 'slotId',
          value: id,
        },
        title: {
          label: priceTypeMap[usage.priceType] || '',
          value: `${getFixedDeposit({ deposit: usage.price })} TEE`,
        },
        options: [...getObjectEntries(sortInfoList(info)), ...getObjectEntries(option), ...timeValue],
      });
    });
};

export const getFormattedOptions = (options?: TeeOfferOption[]): FormattedBilletData[] => {
  if (!options) return [];
  return options.map((item) => {
    const {
      id, usage, info,
    } = item;
    const timeValue = getTimeValue(usage);
    return ({
      id: {
        label: 'optionId',
        value: id,
      },
      title: {
        label: priceTypeMap[usage.priceType] || '',
        value: `${getFixedDeposit({ deposit: usage.price })} TEE`,
      },
      options: [...getObjectEntries(info), ...timeValue],
    });
  });
};
