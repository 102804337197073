import { useAuth } from 'hooks/auth/useAuth';
import { useSPFiles, UseSPFiles } from './useSPFiles';
import { useSecretKeeperFiles, UseSecretKeeperFiles } from './useSecretKeeperFiles';

export type UseFiles = UseSPFiles | UseSecretKeeperFiles;

export const useFiles = (skip?: boolean): UseFiles => {
  const { isProviderSPProvider } = useAuth();
  const spFiles = useSPFiles(skip);
  const secretKeeperFiles = useSecretKeeperFiles(skip);

  return isProviderSPProvider ? spFiles : secretKeeperFiles;
};