import { useCallback, useMemo } from 'react';

import { Box } from 'uikitv2/Box';
import { Tabs } from 'uikitv2/Tabs';
import { OfferAbout } from 'components/OfferAbout';
import classes from './Left.module.scss';
import { Restricted } from './Restricted';
import { Pricing } from './Pricing';
import { LeftComponentProps, OfferTabs } from './types';
import { getTabList, getInitialTab } from './helpers';

export const Left = ({
  data, selectedSlots, loading, onAddSlot, onDeleteSlot, isCanUpdateSlot, isShowCounter, isTee, tab, onChangeTab,
}: LeftComponentProps) => {
  const {
    name, about = '', restricted = [], type, slots, options, configuration,
  } = data || {};
  const list = useMemo(
    () => getTabList(!restricted.length ? [OfferTabs.restricted] : [], isTee),
    [isTee, restricted],
  );
  const activeTab = useMemo(() => getInitialTab(list, tab), [tab, list]);
  const onClick = useCallback((val: string) => {
    onChangeTab(val as OfferTabs);
  }, [onChangeTab]);

  return (
    <Box direction="column" className={classes.content}>
      <Box className={classes.title}>
        {name}
      </Box>
      <Tabs {...{
        list,
        classNameItem: classes.item,
        classNameWrap: classes.wrap,
        active: activeTab,
        onClick,
        alignLeft: true,
      }}
      />
      {activeTab === OfferTabs.about && (
        <OfferAbout text={about} configuration={configuration} titleConfiguration="Compute Offer" />
      )}
      {activeTab === OfferTabs.pricing && (
        <Pricing {...{
          type,
          slots,
          options,
          selectedSlots,
          loading,
          onDeleteSlot,
          onAddSlot,
          isCanUpdateSlot,
          isShowCounter,
        }}
        />
      )}
      {activeTab === OfferTabs.restricted && (
        <Restricted ids={restricted} />
      )}
    </Box>
  );
};
